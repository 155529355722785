import UniversalConfig from '@/config/universal'

function hashToSearchContext(hash) {
  // This will work even for empty hashes, as substring will return an empty string for empty strings
  // .substring(1) since location.hash returns "#<someanchor>"
  const possibleContext = hash.substring(1)
  if (possibleContext.startsWith(UniversalConfig.search_context.prefix)) {
    return possibleContext
  } else {
    return false
  }
}

function getCurrentSearchContext() {
  return hashToSearchContext(window.location.hash)
}

export { hashToSearchContext, getCurrentSearchContext }
