// helper functions for handling google tag manager events in data layer
import { sha256 } from 'js-sha256'

const onListingPageEvent = ({ jobIds }) => {
  if (!jobIds || jobIds.length === 0) {
    return
  }

  return {
    PageType: 'ListingPage',
    ProductIDList: jobIds.slice(0, 3).map(id => `${id}`)
  }
}

const onProductPageEvent = ({ job }) => {
  if (!job || !job.id) {
    return
  }

  const locationId = job.employerLocation
    ? { location_id: job.employerLocation }
    : {}

  // TODO GTM docu says job view == purchase event
  // https://support.google.com/google-ads/answer/7305793?hl=de#zippy=%2Cjobs
  return {
    event: 'purchase',
    PageType: 'ProductPage',
    ProductID: `${job.id}`,
    items: [
      {
        id: `${job.id}`,
        google_business_vertical: 'jobs',
        ...locationId
      }
    ]
  }
}

const onTransactionPageEvent = ({ email, job }) => {
  if (!job || !job.id) {
    return
  }

  const productObj = { id: `${job.id}`, price: 1, quantity: 1 }
  return {
    event: 'job_application_intent',
    PageType: 'TransactionPage',
    ProductTransactionProducts: [productObj],
    TransactionID: new Date().getTime(),
    ...(email ? { email: sha256(email) } : {})
  }
}

const onRegistrationSuccess = () => {
  return { event: 'registration' }
}

const pushEvent = event => {
  // GTMTracking is handling the first event itself so we don't run into timing issues
  // and can simply check if GTM is ready
  if (globalThis.GTM && globalThis.GTM.loaded && globalThis.dataLayer) {
    globalThis.dataLayer.push(event)
  }
}

// TODO device type handling?
const EVENT_HANDLERS = {
  listingPage: onListingPageEvent,
  productPage: onProductPageEvent,
  transactionPage: onTransactionPageEvent,
  registrationSuccess: onRegistrationSuccess
}

export const registerGoogleTagManagerEvent = (event, data, options = {}) => {
  const handler = EVENT_HANDLERS[event]
  if (handler && typeof handler === 'function') {
    const eventObject = handler(data, options)
    if (!options.dryRun && eventObject) {
      pushEvent(eventObject)
    }
  }
}
