const config = {}

function importAll(r) {
  r.keys().forEach(key => {
    Object.assign(config, r(key))
  })
}

importAll(require.context('../../../config/universal', true, /\.ya?ml$/))

export default config
