import { mapConfig } from '@/webtrekk_tracking/config'
import isEqual from 'lodash-es/isEqual'

export const trackingContext = {
  bind: (el, binding, _vnode) => {
    el.dataset.acContext = JSON.stringify(mapConfig(binding.value))
  },
  update: (el, binding, _vnode) => {
    if (!isEqual(binding.oldValue, binding.value)) {
      // prevent jquery from caching changed tracking-context values whilst keeping $().data cache for tracking lookups
      $(el).removeData('ac-context')
      el.dataset.acContext = JSON.stringify(mapConfig(binding.value))
    }
  }
}

export const manualTrack = (el, config) => {
  EventBus.trigger('tracking:vueEventTrack', { el, config: mapConfig(config) })
}

export const clickTrack = {
  bind: (el, binding, _vnode) => {
    el.$clickTrackData = binding.value
    const handler = () => {
      manualTrack(el, el.$clickTrackData)
    }
    el.addEventListener('click', handler)
  },
  update: (el, binding, _vnode) => {
    el.$clickTrackData = binding.value
  }
}
