import Vue from 'vue'
import Vuex from 'vuex'
import VTooltip from 'v-tooltip'

import I18n from 'i18n-js/index.js.erb'
import flatMap from 'lodash-es/flatMap'

import translateFilter from '@/vue/filters/translate'
import SvgIcon from '@/vue/components/svg_icon.vue'

import scrollsIntoView from '@/vue/directives/scrolls_into_view'
import { clickTrack, trackingContext } from '@/vue/directives/tracking'
import storeConfig from '@/vue/store/index'

const mountableComponents = {
  Accordion: () => import('@/accordion/accordion.vue'),
  Event: () => import('@/my_academics/event.vue'),
  EventsList: () => import('@/my_academics/events_list.vue'),
  Modal: () => import('@/components/modal.vue'),
  Session: () => import('@/components/session.vue'),
  Registration: () =>
    import('@/vue/components/fused/sessions/registration.vue'),
  PhdTest: () => import('@/phd_test/phd_test.vue'),
  RandomJobs: () => import('@/vue/components/widgets/random_jobs.vue'),
  SearchProfileEdit: () => import('@/my_academics/search_profile_edit.vue'),
  SearchProfileFrequency: () =>
    import('@/my_academics/search_profile_frequency.vue'),
  DeactivateSearchProfile: () =>
    import('@/my_academics/deactivate_search_profile.vue'),
  ChangeSearchProfileFrequencyDialog: () =>
    import('@/my_academics/change_search_profile_frequency_dialog.vue'),
  UnsubscribeMailDialog: () =>
    import('@/my_academics/unsubscribe_mail_dialog.vue'),
  ExpiredDialog: () => import('@/actions/expired_dialog.vue'),
  Tooltip: () => import('@/vue/components/fused/tooltip.vue'),
  FusedHeaderNav: () => import('@/vue/components/fused/header_nav.vue'),
  JobDetail: () => import('@/vue/components/fused/job_detail.vue'),
  MailSettings: () => import('@/vue/components/fused/mail_settings.vue')
}

I18n.defaultLocale = global.I18n.defaultLocale
I18n.locale = global.I18n.locale

Vue.component('SvgIcon', SvgIcon)
Vue.filter('t', translateFilter(I18n))
Vue.directive('scrolls-into-view', scrollsIntoView)
Vue.directive('tracking-context', trackingContext)
Vue.directive('click-track', clickTrack)
Vue.use(Vuex)
Vue.use(VTooltip)

const store = new Vuex.Store(storeConfig)

store.replaceState({ ...store.state, ...(global.VuexStore || {}) })

const mountVueComponents = () => {
  Object.entries(mountableComponents).forEach(([componentName, component]) => {
    const className = `nyx-${componentName}`
    // Make sure store is ready before mounting components (mainly to cope with legacy code)
    setTimeout(() => {
      flatMap([...document.getElementsByClassName(className)], el => {
        const props = JSON.parse(el.dataset.props || '{}')
        // Don't mount an element that already belongs to a Vue instance
        if (!el.__vue__) {
          new Vue({ store, el, render: h => h(component, { props }) })
        }
      })
    })
  })
}

EventBus.on('vue:mount', mountVueComponents)

document.addEventListener('DOMContentLoaded', () => {
  mountVueComponents()
})
